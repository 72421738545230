import { Link } from 'react-router-dom'
import arrow_img from '../../../assets/images/left.svg'
import { useSelector } from 'react-redux'
import { selectNavbarHideMobileState, selectNavbarHideState } from '../../../services/global/globalSlice'
import { isMobile } from 'react-device-detect';

export default function FolderTopBar ({t, folderData, current=false}) {

    const hideNavbar = useSelector(selectNavbarHideState)
    const hideNavbarMobile = useSelector(selectNavbarHideMobileState)
    

    return (
        <div className={`folder_topbar ${hideNavbar || (isMobile && hideNavbarMobile) ? 'full-width' : ""}`}>
            {
                folderData?.parent_folder_id || folderData?.folder_id ?
                    <Link to={`/folder/${current ? folderData?.folder_id : folderData?.parent_folder_id}`}>
                        <img src={arrow_img} alt=''/>
                        <span>{current ? folderData.name : folderData?.parent_folder_data?.name || t.buttons.back}</span>
                    </Link>
                : ""
            }
        </div>
    )
}