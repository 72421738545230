import { useNavigate } from 'react-router-dom'
import preview from '../../../assets/images/folders/video_preview.png'

import { API_URL } from '../../../config/constants'
import correct_img from '../../../assets/images/folders/correct.svg'
import incomplete_img from '../../../assets/images/ofa/incomplete_folder.svg'
import { isMobile, isTablet } from 'react-device-detect'
import { useState } from 'react'
import VideoPlayerInline from './VideoPlayerInline'

import SecuredImage from '../../../components/_partials/SecuredImage'

export default function Video ({video, folder, onVideoEnd}) {

    const navigate = useNavigate()
    const [showVideoPlayer, setShowVideoPlayer] = useState(false)

    const openVideo = () => {
        if(!isMobile || isTablet )
            navigate(`/video/play/${folder.folder_id}/${video.file_id}`, {replace: true, state: {folderData: folder}})
        else
            setShowVideoPlayer(true)
    }

    return (
        <>
        {
            showVideoPlayer ?
                <div className="video pointer" >
                    <VideoPlayerInline poster={video.poster_image_url} videoId={video.file_id} onEnd={onVideoEnd} />
                    <div className="d-flex align-items-start mt-2">
                        <img src={!video?.completed ? incomplete_img : correct_img} className='mt-1 me-1' alt="" />
                        <span className='text-start'>{video.name}</span>
                    </div>
                </div>
            :
                <div className="video pointer" onClick={openVideo} >
                    <SecuredImage imageUrl={video.poster_image_url || preview} className='mb-2 video_preview' alt=""/>
                    <div className="d-flex align-items-start">
                        <img src={!video?.completed ? incomplete_img : correct_img} className=' mt-1 me-1' alt="" />
                        <span className='text-start'>{video.name}</span>
                    </div>
                </div>
        }
            
        </>
    )
}